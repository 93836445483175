<script>
import Vue from "vue";
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import {getApplicationLeaveDetail,approveLeaveApplication} from '@/apis/leave'
import store from '@/state/store'
import {lookup2,getLookupItemText} from '@/apis/common'
import {
  required
} from "vuelidate/lib/validators";
export default {
  page: {
    title: "Leave Management",
    meta: [{ name: "description", content: appConfig.description }]
  },
  validations: {
        form: { 
            comment:{ 
                required,
            },      
        },
    },  
  components: { Layout},
  props:{
    leaveNo:{
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: "Leave Management",  
      detail:{},  
      submitted:false,
      lookupRawData:{},
      lookupData:{
          leaveType:[],
          leaveStatus:[]
      },       
      form:{
        comment:"",
        status:""
      }
    };
  },
  watch: {
        '$store.state.auth.language': function() {
            this.changeLookupLanguage()
        }
    },  
  computed:{
    showApproval(){
      if(this.detail.status=='PA' && store.state.auth.currentUser.userName==this.detail.approver){
        return true
      }else{
        return false
      }
    },
    showClose(){
      if(this.detail.status=='PA' && store.state.auth.currentUser.userName==this.detail.applicant){
        return true
      }else{
        return false
      }
    }
  },
  created(){
    this.init()
  },
  mounted() {
  },
  methods: { 
    loadData(){
      getApplicationLeaveDetail(this.leaveNo).then((res)=>{
          this.detail = res.data
      })
    },
    handleBack(){
      this.$router.go(-1);
    },
    handleConfirm(type){
      this.submitted = true;
      if(type==0){
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
      }
      if(type==0){
        this.form.status = "R"
      }else if(type==1){
        this.form.status = "A"
      }else{
        this.form.status = "C"
      }
      approveLeaveApplication(this.detail.leaveNo,this.form).then(()=>{
        Vue.swal(this.$t("common.alertfinish"));
        this.loadData()
      })
    },
    async init(){
        this.lookupRawData = await lookup2("LEAVE_STATUS,LEAVE_TYPE");
        this.lookupData.leaveType = this.lookupRawData["LEAVE_TYPE"].map(item=>{return {...item,labelCn:item.label}});
        this.lookupData.leaveStatus = this.lookupRawData["LEAVE_STATUS"].map(item=>{return {...item,labelCn:item.label}});
        this.changeLookupLanguage()
        this.loadData()
    },
    changeLookupLanguage(){
        if(localStorage.getItem('language')=="zh-CN"){
            this.lookupData.leaveType = this.lookupData.leaveType.map(item=>{return {...item,label:item.labelCn}});
            this.lookupData.leaveStatus = this.lookupData.leaveStatus.map(item=>{return {...item,label:item.labelCn}});
        }else{
            this.lookupData.leaveType = this.lookupData.leaveType.map(item=>{return {...item,label:item.labelEn}});
            this.lookupData.leaveStatus = this.lookupData.leaveStatus.map(item=>{return {...item,label:item.labelCn}});
        }
    },   
    formatLookupText(typeCode,value){
        return getLookupItemText(this.lookupRawData[typeCode],value);
    },     
  }
};
</script>

<template>
  <Layout>
    <div class="row mt-4">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <h4 class="card-title">{{$t("leave.pagename")}}</h4>
              </div>
              <div class="col-sm-6">
                <div class="float-end d-none d-md-block">
                  <a href="javascript:" @click="handleBack" class="btn btn-success">{{$t("actions.back")}}</a>
                </div>
              </div>
            </div>
            <div class="mt-1">
              <table class="table table-bordered table-striped mb-0">
                <tbody>
                  <tr>
                    <th class="text-nowrap" scope="row">{{ $t("leave.status") }}</th>
                    <td >{{ formatLookupText("LEAVE_STATUS",detail.status) }}</td>
                  </tr>                  
                  <tr>
                    <th class="text-nowrap" scope="row">{{ $t("leave.applicant") }}</th>
                    <td class="username">{{ detail.applicant }}</td>
                  </tr>
                  <tr>
                    <th class="text-nowrap" scope="row">{{ $t("leave.leaveType") }}</th>
                    <td>{{ formatLookupText("LEAVE_TYPE",detail.leaveType) }}</td>
                  </tr>
                  <tr>
                    <th class="text-nowrap" scope="row">{{ $t("leave.leaveDate") }}</th>
                    <td>{{ detail.startDate }}-{{ detail.endDate }}</td>
                  </tr>
                  <tr>
                    <th class="text-nowrap" scope="row">{{ $t("leave.fileOrgName") }}</th>
                    <td colspan="6">{{ $t("common.download") }}</td>
                  </tr>
                </tbody>
            </table>            
            </div>
            <div class="row">
              <div class="col-xl-12">
                <form class="needs-validation">
                  <div class="row">
                    <div class="col-xl-12  mb-3">
                      <label class="mb-1 mt-3 font-weight-medium">{{$t("leave.comment")}}</label>
                    <textarea
                      v-model="form.comment"
                      class="form-control"
                      :maxlength="225"
                      rows="3"
                      :class="{
                        'is-invalid': submitted && $v.form.comment.$error,
                    }"    
                    ></textarea>         
                    <div
                        v-if="submitted && $v.form.comment.$error"
                        class="invalid-feedback"
                        >
                            <span v-if="!$v.form.comment.required">
                                {{$t("common.requiredError")}}.
                            </span>
                    </div>      
                    </div>          
                  </div> 
                  <div class="row">                  
                          <div class="col-md-12 mb-3">
                              <button type="button" class="btn btn-info ms-1" @click="handleConfirm(1)" v-if="showApproval">{{$t("leave.submitApproval")}}</button>
                              <button type="button" class="btn btn-success ms-1" @click="handleConfirm(0)"  v-if="showApproval">{{$t("leave.reject")}}</button>
                              <button type="button" class="btn btn-light ms-1" @click="handleConfirm(2)"  v-if="showClose">{{$t("actions.close")}}</button>
                          </div>        
                  </div>
                  </form>    
              </div>
              <div class="col-xl-12" v-if="detail.logs!=null && detail.logs.length>0">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title mb-4">{{ $t("leave.logs") }}</h4>
                    <ol class="activity-feed">
                      <li class="feed-item" v-for="activity in detail.logs" :key="activity.id">
                        <div class="feed-item-list">
                          <span class="date">{{activity.createDate}}</span>
                          <span class="activity-text"><span class="username" style="font-weight: bold;">{{activity.createUser}}</span> - {{activity.comment}}</span>
                        </div>
                      </li>
                    </ol>
                  </div>
                  <!-- end card-body -->
                </div>
              </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
<style lang="scss" scoped>
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
.username::first-letter {
  text-transform: uppercase;
}
</style>